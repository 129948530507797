import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { GlobalContactForm } from '@phx-husky/global-contact-form';

import { Layout } from 'layout';

const ContactUs: FC<ContactUsPageTypes.ContactUsPageProps> = ({
  data: {
    contactUsPage: { urls, seo, globalForm },
  },
}) => (
  <Layout {...{ urls, seo, background: 'sauvignon', className: 'contact-page' }}>
    {globalForm ? (
      <GlobalContactForm src={globalForm} id="phx-global-form-gatsby" title="global-contact-form" />
    ) : null}
  </Layout>
);

export const query = graphql`
  query ContactUsPageQuery($lang: String) {
    contactUsPage(lang: { eq: $lang }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      globalForm
    }
  }
`;

export default ContactUs;
